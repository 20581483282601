import React, { useContext } from "react";
import { FooterContext } from "../../../context/FooterContext";
import { Wallet, WalletTokensBurn } from "../../../types/Wallet";
import { burnAccounts } from "../../../services/solanaService";
import { burnTokens } from "../../../services/solanaServiceTokensBurn";
import { useWallet } from "@solana/wallet-adapter-react";
import { useLocation } from "react-router-dom";
import { ConnectWalletButton } from "../Header/ConnectWalletButton";
import { useTranslation } from "react-i18next";
import { trackEvent } from 'services/analytics';

const Footer = () => {
  const { footerData, accounts, tokensBurn, updateAccounts, updateAccountsTokenBurns } = useContext(FooterContext);
  const { publicKey } = useWallet();
  const walletAdapter = useWallet();
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const { t } = useTranslation();
  
  let totalReward = 0

  if (location.pathname === "/"){
    totalReward = accounts.filter((wallet: Wallet) => wallet.metaChecked).length * 0.002;
  }

  if (location.pathname === "/burntokens"){
    totalReward = tokensBurn.filter((wallet: WalletTokensBurn) => wallet.metaChecked && wallet.balance === wallet.balanceBurn).length * 0.002;
  }

  const handleClick = async () => {
    trackEvent('Wallet', 'Burn Click', 'User burn account');
      try {
        const selectedAccounts = accounts.filter(
          (account) => account.metaChecked
        );
        await burnAccounts(publicKey, selectedAccounts, updateAccounts, t);
      } catch (error) {
        console.error("Error during burn process:", error);
      }
  };

  const handleClickTokenBurns = async () => {
    trackEvent('Wallet', 'Burn Click', 'User burn account');
      try {
        const selectedAccounts = tokensBurn.filter(
          (tokensBurn) => tokensBurn.metaChecked
        );
        await burnTokens(publicKey, selectedAccounts, updateAccountsTokenBurns, t);
      } catch (error) {
        console.error("Error during burn process:", error);
      }
  };

  // console.log("Selected accounts:", tokensBurn.filter((tokensBurn) => tokensBurn.metaChecked));

  return (
    <div className="footer d-flex align-items-center justify-content-between">
      <div className="left">Copyright © {currentYear}</div>
      {footerData && (location.pathname === "/" || location.pathname === "/burntokens") ? (
        walletAdapter.connected ? (
          <div className="center burn-button">
            <button
              style={{
                background: "rgb(81, 45, 168)",
                border: "none",
              }}
              className="btn btn-success burn-button burn-button-icon burnz"
              disabled={ location.pathname === "/burntokens" ? tokensBurn.filter((wallet: WalletTokensBurn) => wallet.metaChecked).length === 0 : 
                location.pathname === "/" ?  accounts.filter((wallet: Wallet) => wallet.metaChecked).length === 0 : true}
              onClick={location.pathname === "/burntokens" ? handleClickTokenBurns : handleClick}
            >
              {totalReward > 0 ? (
                <>
                  {t("burn_button")} &nbsp;
                  {(totalReward || 0).toFixed(3)} SOL
                </>
              ) : totalReward === 0 && location.pathname === "/burntokens" ? (
                <>
                  {t("burn_only_token_button")}
                </>
              ) : ( 
                <>
                  {t("burn_button")}
                </>
              )}
            </button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ConnectWalletButton />
          </div>
        )
      ) : null}
      <div className="right social">
        <a
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
          href="https://t.me/dexscreeneronline"
          target={"_blank"}
        >
          <i
            style={{
              color: "var(--vz-header-item-sub-color)",
            }}
            className="lab la-telegram"
          ></i>
        </a>
        <a
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
          href="https://x.com/anubis_trade"
          target={"_blank"}
        >
          <i
            style={{
              color: "var(--vz-header-item-sub-color)",
            }}
            className="lab la-twitter"
          ></i>
        </a>
        <a
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
          href="https://www.youtube.com/@AnubisTradeGroup"
          target={"_blank"}
        >
          <i
            style={{
              color: "var(--vz-header-item-sub-color)",
            }}
            className="lab la-youtube"
          ></i>
        </a>
        <a
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
          rel="noreferrer"
          href="https://medium.com/@anubis-trade"
          target={"_blank"}
        >
          <i
            style={{
              color: "var(--vz-header-item-sub-color)",
            }}
            className="lab la-medium"
          ></i>
        </a>
        <a
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
          href="https://t.me/burnsplbot?start=r-czyj7ih970q"
          target={"_blank"}
        >
          <i
            style={{
              color: "var(--vz-header-item-sub-color)",
            }}
            className="las la-robot"
          ></i>
        </a>
      </div>
    </div>
  );
};

export default Footer;
