import ReactGA from 'react-ga';

const GA_TRACKING_ID = 'G-S344WREC0Z'; // Ваш идентификатор отслеживания Google Analytics
const AW_TRACKING_ID = 'AW-16667877401'; // Ваш идентификатор отслеживания Google Adwords

ReactGA.initialize([
  { trackingId: GA_TRACKING_ID },
  { trackingId: AW_TRACKING_ID }
]);

export const trackPage = (page: string) => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
  };
  
  export const trackEvent = (category: string, action: string, label?: string) => {
    ReactGA.event({
      category,
      action,
      label,
    });
  };