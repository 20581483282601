import React, { useEffect } from "react";
import Route from "./Routes";
import AppWalletProvider from "./components/Common/Wallet/AppWalletProvider";
import Header from "./components/Common/Header/Header";
import Footer from "./components/Common/Footer/Footer";
import { FooterProvider } from "./context/FooterContext";
import { useRefHook } from "./hooks/useRefHook";
import "./assets/scss/themes.scss";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "locales/en/translation.json";
import translationRU from "locales/ru/translation.json";
import translationCN from "locales/cn/translation.json";
import { useTheme } from "./hooks/useTheme";
import { useLocation } from 'react-router-dom'; // добавьте этот импорт
import { trackPage } from "./services/analytics"; // импортируйте trackPage

const resources = {
  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
  cn: {
    translation: translationCN,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  useRefHook();
  const { theme } = useTheme();
  const location = useLocation(); // добавьте этот хук

  useEffect(() => {
    trackPage(location.pathname + location.search); // отслеживание страницы
  }, [location]);

  return (
    <AppWalletProvider>
      <FooterProvider>
        <Header />
        <ToastContainer position="bottom-right" theme={theme} />
        <div className="wrapper">
          <Route />
        </div>
        <div className="push" />
        <Footer />
      </FooterProvider>
    </AppWalletProvider>
  );
}

export default App;