import About from "../pages/About/About";
import BurnPage from "../pages/Burn/BurnPage";
import BurnTokensPage from "../pages/BurnTokens/BurnTokensPage";
import { Navigate } from "react-router";
import Page404 from "../pages/Errors/404";

const publicRoutes: any[] = [
  { path: "", component: <BurnPage /> },
  { path: "BurnTokens", component: <BurnTokensPage /> },
  { path: "About", component: <About /> },
  { path: "404", component: <Page404 /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/" />,
  },
  { path: "*", component: <Navigate to="/404" /> },
];

export { publicRoutes };
