import Cookies from "universal-cookie";
import { useAppDispatch } from "../redux/store";
import { setLayoutTheme } from "../redux/actions";
import { LAYOUT_MODE_TYPES } from "../components/constants/layout";

export const useTheme = () => {
  const dispatch = useAppDispatch();
  const cookies = new Cookies();
  const theme = cookies.get("theme");
  if (theme) {
    dispatch(setLayoutTheme(theme));
    document.documentElement.setAttribute("data-bs-theme", theme);
  }
  return { theme: theme ?? LAYOUT_MODE_TYPES.DARKMODE };
};
