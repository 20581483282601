import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import React from "react";

export const RefreshButtonComponent = ({
  loadWallets,
}: {
  loadWallets: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
      <td colSpan={6}>
        <button
          style={{
            backgroundColor: "#512da8",
            minWidth: 300,
            border: "none",
          }}
          className="btn btn-success burn-button refresh"
          onClick={async () => {
            await loadWallets();
            // await toast(t("table_update"), { type: "default" });
          }}
        >
          {t("refresh")}
        </button>
      </td>
    </tr>
  );
};
