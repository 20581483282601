import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AnimatePresence, motion } from "framer-motion";
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import logo from "assets/images/favicon.png";
import { FooterContext } from "../../context/FooterContext";
import classnames from "classnames";
import { WalletTokensBurn, WalletAmountTokensBurn } from "../../types/Wallet";
import { useWallet } from "@solana/wallet-adapter-react";
import { batchSize, restrictionSize } from "../../constants/batch";
import { useTranslation } from "react-i18next";
import { WalletDetails } from "./WalletDetails";
import { useWs } from "../../hooks/useWs";
import { toast } from "react-toastify";
import BurnedWalletRow from "./BurnedWalletRow";
import { useReferralStats } from "../../hooks/useReferralStats";
import { API_URL } from "../../constants/api";
import "./burning-table.scss";

const BurnPage = () => {
  const { t } = useTranslation();
  document.title = "Anubis Trade - Burn Tokens";

  const { setFooterData, setAccountsTokenBurn, tokensBurn } = useContext(FooterContext);
  const referralStats = useReferralStats();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/burntokens") {
      // Главная страница
      setFooterData("Some data for footer");
    } else {
      setFooterData(null);
    }
  }, [location, setFooterData]);

  const [totalTasks, setTotalTasks] = useState(0);
  const [loadedTasks, setLoadedTasks] = useState(0);
  const [tasksLoaded, setTasksLoaded] = useState(false);
  const [col1, setcol1] = useState<boolean>(true);
  const [col2, setcol2] = useState<boolean>(false);
  const [col3, setcol3] = useState<boolean>(false);
  const [col4, setcol4] = useState<boolean>(false);
  const [col5, setcol5] = useState<boolean>(false);
  const [col6, setcol6] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [selectedWallets, setSelectedWallets] = useState(new Set());
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [accountsAmountTokensBurn, setAmountsTokenBurn] = useState<WalletAmountTokensBurn[]>([]);

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol5(false);
    setcol6(false);
  };

  const t_col2 = () => {
    setcol1(false);
    setcol2(!col2);
    setcol3(false);
    setcol4(false);
    setcol5(false);
    setcol6(false);
  };

  const t_col3 = () => {
    setcol1(false);
    setcol2(false);
    setcol3(!col3);
    setcol4(false);
    setcol5(false);
    setcol6(false);
  };

  const t_col4 = () => {
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol4(!col4);
    setcol5(false);
    setcol6(false);
  };

  const t_col5 = () => {
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol5(!col5);
    setcol6(false);
  };

  const t_col6 = () => {
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol5(false);
    setcol6(!col6);
  };

  const { publicKey } = useWallet(); // change to const  
  const [selectedAll, setSelectedAll] = useState(false);
  const [reset, setReset] = useState(false);

  const loadWallets = async () => {
    setTasksLoaded(false);
    setAccountsTokenBurn([]);
    setLoadedTasks(0);

    setTimeout(() => {
      setReset(!reset);
    }, 2000);
  };

  useEffect(() => {
    setAccountsTokenBurn([]);
    setLoadedTasks(0);
    setTasksLoaded(false);
    setTotalTasks(0);
  }, [publicKey, reset]);

  useEffect(() => {
    if (publicKey) {
      const eventSource = new EventSource(`${API_URL}/api/wallet-tokens/${publicKey}`);
  
      eventSource.onmessage = (event) => {
        const data = event.data;
        if (data.startsWith("total:")) {
          const total = parseInt(data.split(":")[1].trim());
          setTotalTasks(total);
        } else if (data !== "loading") {
          const parsedData = JSON.parse(data);
          if (parsedData.loaded !== undefined) {
            setLoadedTasks(parsedData.loaded);
          }
          if (parsedData.wallets) {
            // Получаем текущие токены для страницы
            
            const currentTokens = parsedData.wallets.map((wallet: WalletTokensBurn) => {
              const existingAccount = accountsAmountTokensBurn.find(acc => acc.address === wallet.address);
              return {
                ...wallet,
                // Устанавливаем metaChecked только для токенов на текущей странице
                balanceBurn: existingAccount ? existingAccount.balanceBurn : wallet.balanceBurn,
                metaChecked: isFirstLoad ? wallet.metaChecked : selectedWallets.has(wallet.address),
              };
            });
            
            if (isFirstLoad) {
              const initialSelectedWallets = new Set(
                currentTokens
                  .filter(wallet => wallet.metaChecked) // Фильтруем только те, что выделены
                  .map(wallet => wallet.address)
              );
              setSelectedWallets(initialSelectedWallets);
              setIsFirstLoad(false); // Обновляем состояние, чтобы не выполнять это снова
            }

            setAccountsTokenBurn(currentTokens);
          }
          if (parsedData.done) {
            setTasksLoaded(true);
          }
        }
      };
  
      eventSource.onerror = (err) => {
        eventSource.close();
      };
  
      return () => {
        eventSource.close();
      };
    }
  }, [publicKey, batchSize, restrictionSize, reset, currentPage, itemsPerPage]);  

  const { burnedWallets, onlyUserBurns, setOnlyUserBurns, burnedLoaded } = useWs();
  // console.log("Rendering BurnedWalletsTable with burnedWallets:", burnedWallets);

  const progress = totalTasks ? (loadedTasks / totalTasks) * 100 : 0;
  const currentWallets = tokensBurn.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // const handleWalletSelect = (index: number): void => {
  //   const walletIndex = (currentPage - 1) * itemsPerPage + index; // Найти индекс в полном массиве
  //   const newData = [...tokensBurn];
  //   newData[walletIndex].metaChecked = !newData[walletIndex].metaChecked; // Переключение состояния выделения
  
  //   setAccountsTokenBurn(newData);
  //   setSelectedAll(newData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).every(wallet => wallet.metaChecked)); // Проверяем, выбраны ли все текущие
  // };

  const [tokenAmounts, setTokenAmounts] = useState<{ [key: string]: number }>(
    tokensBurn.reduce((acc, account) => {
      acc[account.address] = account.balance;
      return acc;
    }, {} as { [key: string]: number })
  );

  useEffect(() => {
    setTokenAmounts(
      tokensBurn.reduce((acc, account) => {
        acc[account.address] = account.balance;
        return acc;
      }, {} as { [key: string]: number })
    );
  }, [tokensBurn]); 

  const handlePercentageClick = (address: string, percentage: number) => {
    setTokenAmounts((prevTokenAmounts) => {
      const selectedAccount = tokensBurn.find(account => account.address === address);
      const newData = [...tokensBurn]; // Копируем массив токенов
  
      if (!selectedAccount) return prevTokenAmounts;
  
      const newBurnAmount = Number((selectedAccount.balance * percentage).toFixed(selectedAccount.info.decimals));
  
      // Обновляем значение для balanceBurn
      setAmountsTokenBurn((prevAccounts: WalletAmountTokensBurn[]) => {
        const existingAccount = prevAccounts.find(acc => acc.address === address);
  
        if (existingAccount) {
          // Если аккаунт уже существует, обновляем только balanceBurn
          return prevAccounts.map((account) =>
            account.address === address
              ? { ...account, balanceBurn: newBurnAmount }
              : account
          );
        } else {
          // Если аккаунт не существует, добавляем новый объект
          return [...prevAccounts, { address, balanceBurn: newBurnAmount }];
        }
      });
  
      // Обновляем значение balanceBurn в newData
      newData.forEach((account) => {
        const existingAmount = accountsAmountTokensBurn.find(acc => acc.address === account.address);
        account.balanceBurn = existingAmount ? existingAmount.balanceBurn : account.balanceBurn;
      });
  
      // Обновляем только для выбранного адреса
      const updatedNewData = newData.map(account =>
        account.address === address
          ? { ...account, balanceBurn: newBurnAmount }
          : account
      );
  
      setAccountsTokenBurn(updatedNewData); // Обновляем состояние accountsTokenBurn
      return {
        ...prevTokenAmounts,
        [address]: newBurnAmount,
      };
    });
  };

  const handleInputBurnTokenChange = (address: string, newValue: string) => {
    const newBurnAmount = parseFloat(newValue); // Преобразуем строку в число
    const newData = [...tokensBurn]; // Копируем массив токенов

    if (isNaN(newBurnAmount)) return; // Если введено не число, выходим
  
    // Обновляем значение для balanceBurn
    setAmountsTokenBurn((prevAccounts: WalletAmountTokensBurn[]) => {
      const existingAccount = prevAccounts.find(acc => acc.address === address);
  
      if (existingAccount) {
        // Если аккаунт уже существует, обновляем только balanceBurn
        return prevAccounts.map((account) =>
          account.address === address
            ? { ...account, balanceBurn: newBurnAmount }
            : account
        );
      } else {
        // Если аккаунт не существует, добавляем новый объект
        return [...prevAccounts, { address, balanceBurn: newBurnAmount }];
      }
    });

    // Обновляем значение balanceBurn в newData
    newData.forEach((account) => {
      const existingAmount = accountsAmountTokensBurn.find(acc => acc.address === account.address);
      account.balanceBurn = existingAmount ? existingAmount.balanceBurn : account.balanceBurn;
    });

    // Обновляем только для выбранного адреса
    const updatedNewData = newData.map(account =>
      account.address === address
        ? { ...account, balanceBurn: newBurnAmount }
        : account
    );
    setAccountsTokenBurn(updatedNewData);
  }; 

  const handleWalletSelect = (walletAddress) => {
    // Создаем новый Set на основе текущего состояния selectedWallets
    const newSelectedWallets = new Set(selectedWallets);
    const newData = [...tokensBurn];

    // Проверяем, выбран ли кошелек
    if (newSelectedWallets.has(walletAddress)) {
        newSelectedWallets.delete(walletAddress);
    } else {
        newSelectedWallets.add(walletAddress);
    }

    // Обновляем состояние выделенных кошельков
    setSelectedWallets(newSelectedWallets);

    // Обновляем состояние tokensBurn для отображения выделения
    newData.forEach((account) => {
        account.metaChecked = newSelectedWallets.has(account.address);
    });

    // Обновляем состояние tokensBurn
    setAccountsTokenBurn(newData);
  };

  const handleSelectAll = (): void => {
      const newData = [...tokensBurn];
      const isAllSelected = currentWallets.every(wallet => wallet.metaChecked); // Проверяем, выбраны ли все текущие

      // Переключение выделения для текущих кошельков
      currentWallets.forEach((wallet, index) => {
          newData[(currentPage - 1) * itemsPerPage + index].metaChecked = !isAllSelected;
          
          // Добавляем или удаляем адрес кошелька из набора выделенных
          if (!isAllSelected) {
              setSelectedWallets(prev => new Set(prev).add(wallet.address));
          } else {
              setSelectedWallets(prev => {
                  const newSelected = new Set(prev);
                  newSelected.delete(wallet.address);
                  return newSelected;
              });
          }
      });

      setAccountsTokenBurn(newData);
      setSelectedAll(!isAllSelected); // Обновление состояния выделения всех
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  
  const handleItemsPerPageChange = (items: number) => {
    setItemsPerPage(items);
    setCurrentPage(1); // Сбросить на первую страницу при изменении количества элементов
  };

  const wallet = useWallet();

  return (
    <>
    <Helmet>
        <title>Burn Tokens and Close Accounts on Solana - Anubis Trade</title>
        <meta name="description" content="Burn unused tokens and close your Solana accounts securely. Optimize your wallet and manage your assets efficiently with Anubis Trade." />
        <meta name="keywords" content="solana, burn tokens, close accounts, token management, asset optimization, blockchain, SOL, Anubis Trade" />    
        <meta name="author" content="@anubistrade" />
    </Helmet> 
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={8}>
              <div className="live-preview">
                <div className="table-responsive">
                  <div className="alert alert-warning" role="alert">
                    <strong>{t("warning_table_strong")}</strong>
                    {t("warning_table_t1")} <b>20</b> {t("warning_table_t2")}
                  </div>

                  <Table className="align-middle table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{ width: "42px" }}>
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            checked={selectedAll}
                            onChange={handleSelectAll}
                          ></Input>
                        </th>
                        <th scope="col"> {t("burn_table_token")}</th>
                        <th scope="col"> {t("burn_table_name_token")}</th>
                        <th scope="col"> {t("burn_table_cost")}</th>
                        <th scope="col"> {t("burn_table_tvl")}</th>
                        <th scope="col"> {t("burn_table_balance")}</th>
                      </tr>
                    </thead>
                    {wallet.connected ? (
                      <WalletDetails
                        accounts={tokensBurn}
                        handlePercentageClick={handlePercentageClick}
                        handleWalletSelect={handleWalletSelect}
                        handleInputBurnTokenChange={handleInputBurnTokenChange}
                        loadWallets={loadWallets}
                        progress={progress}
                        tasksLoaded={tasksLoaded}
                        totalTasks={totalTasks}
                        loadedTasks={loadedTasks}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        onPageChange={handlePageChange}
                        onItemsPerPageChange={handleItemsPerPageChange}
                      />
                    ) : null}
                  </Table>
                  {!wallet.connected && (
                    <>
                      <div className="ratio ratio-16x9">
                        <iframe
                          src="https://www.youtube.com/embed/MjylzQVktDg"
                          title="Burn Empty Associated Accounts on Solana | Anubis.Trade Service Demo | Earn 0.002 SOL"
                          allowFullScreen
                        ></iframe>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{
                            width: "50%",
                            padding: 40,
                            background: "var(--vz-secondary-bg)",
                            borderRadius: "50%",
                            marginTop: 53,
                            marginBottom: 53,
                          }}
                          src={logo}
                          alt=""
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Col>

            <Col
              style={{
                paddingBottom: 20,
              }}
              xxl={4}
            >
              <Card className="ribbon-box border shadow-none mb-lg-0 mt-3">
                <CardBody>
                  <div className="ribbon ribbon-primary round-shape">
                    {t("commission")}
                  </div>
                  <div
                    style={{
                      marginTop: 20,
                    }}
                    className="ribbon-content mt-4 text-muted"
                  >
                    <p className="mb-0">{t("register")}</p>
                    <a
                      target={"_blank"}
                      className="text-decoration-underline ml-2"
                      href="https://t.me/burnsplbot?start=r-gw70bnv0wg"
                    >
                      {t("join_telegram")}
                    </a>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-3">
                {wallet.connected ? (
                  <Card
                    className="referral-info"
                    style={{
                      padding: 20,
                    }}
                  >
                    {wallet.connected ? (
                      <>
                        <h5>
                          <i
                            style={{
                              fontSize: 25,
                              marginRight: 10,
                              color: "var(--vz-header-item-sub-color)",
                            }}
                            className="las las la-link"
                          ></i>
                          {t("ref_links")}
                        </h5>
                        <>
                          {((referralStats as any)?.refLinks ?? ["", ""])
                            .filter((e) => e !== undefined)
                            .map((e, index) => {
                              return (
                                <h6 key={index}>
                                  {e}
                                  <Button
                                    onClick={() => {
                                      navigator.clipboard.writeText(e);
                                      toast("Referral link copied!");
                                    }}
                                    style={{
                                      marginLeft: 20,
                                    }}
                                    className="w-xs"
                                    color="warning"
                                    size="sm"
                                  >
                                    {t("copy")}
                                  </Button>
                                </h6>
                              );
                            })}
                        </>
                      </>
                    ) : null}
                    {referralStats ? (
                      <>
                        <h5 className="mt-3">
                          <i
                            style={{
                              fontSize: 25,
                              marginRight: 10,
                              color: "var(--vz-header-item-sub-color)",
                            }}
                            className="las la-user-friends"
                          ></i>
                          {t("ref_stats")}
                        </h5>
                        <ul>
                          <li>
                            {t("you_have")}
                            {referralStats.totalReferrals}
                             {t("referral")}
                          </li>
                          <li>
                            {t("total_referral_wallets")}
                            {" " + referralStats.totalReferralWalletsCount}
                          </li>
                          <li>
                            {t("referral_percentage_rewards")}
                            {" " + referralStats.referralPercentage}%
                          </li>
                          <li>
                            {t("total_burned_accs")}
                            {" " + referralStats.totalBurnedAccounts}
                          </li>
                          <li>
                            {t("total_earnings")}{" "}
                            {referralStats.formattedEarnings} SOL
                          </li>
                        </ul>
                      </>
                    ) : null}

                    <div>
                      {wallet.connected ? (
                        <Card
                          style={{
                            marginTop: 20,
                          }}
                          className="ribbon-box border shadow-none mb-lg-0 "
                        >
                          <CardBody>
                            <div
                              style={{
                                background: "var(--vz-danger)",
                              }}
                              className="ribbon ribbon-error round-shape"
                            >
                              {t("take_care")}
                            </div>
                            <div
                              style={{
                                marginTop: 20,
                              }}
                              className="ribbon-content mt-4 text-muted"
                            >
                              <p className="mb-0">
                                <i
                                  style={{
                                    position: "relative",
                                    top: 4,
                                    marginRight: 8,
                                    fontSize: 25,
                                    color: "var(--vz-primary-text-emphasis)",
                                  }}
                                  className="las la-exclamation-triangle"
                                ></i>
                                {t("minimal_sol")}
                              </p>
                            </div>
                          </CardBody>
                        </Card>
                      ) : null}
                    </div>
                  </Card>
                ) : null}
                <>
                <Table className="align-middle table-nowrap mb-0 wallet-table burning-table" layoutId={"table"}>
                  <thead
                    style={{
                      boxShadow: "inset 0 calc(-1 * var(--vz-accordion-border-width)) 0 var(--vz-accordion-border-color)",
                    }}
                    className="table-light"
                  >
                    <tr style={{ color: "rgb(100, 147, 233)" }}>
                      <td style={{ backgroundColor: "rgba(61, 120, 227, 0.05)" }} colSpan={3}>
                        <div style={{ padding: 10, display: "flex", justifyContent: "space-between", alignItems: "center", width: 250 }}>
                          <h5 style={{ color: "rgb(100, 147, 233)" }} className="m-0">
                            {t("last_burned_accounts")}
                          </h5>
                        </div>
                      </td>
                      <td
                        style={{
                          backgroundColor: "rgba(61, 120, 227, 0.05)",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                        colSpan={2}
                      >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div className="form-check">
                            <div className="form-check form-switch mb-2">
                              <Input
                                defaultChecked={onlyUserBurns}
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                style={{ marginRight: 10 }}
                                id="flexSwitchCheckDefault"
                                onClick={() => setOnlyUserBurns(!onlyUserBurns)}
                              />
                              <Label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                {t("only_your_burns")}
                              </Label>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <th scope="col" style={{ width: "42px" }}></th>
                      <th scope="col">{t("wallet")}</th>
                      <th scope="col">{t("accounts")}</th>
                      <th scope="col" style={{ width: "42px" }}>
                        {t("claimed_sol")}
                      </th>
                      <th scope="col" style={{ width: "42px" }}>
                        {t("status")}
                      </th>
                    </tr>
                  </thead>
                  <AnimatePresence>
                    <motion.tbody>
                      {burnedWallets.length > 0 &&
                        burnedWallets.slice(0, 10).map((burnedWallet) => (
                          <BurnedWalletRow burnedLoaded={burnedLoaded} key={burnedWallet.tx + burnedWallet.address} burnedWallet={burnedWallet} />
                        ))}
                    </motion.tbody>
                  </AnimatePresence>
                </Table>
                </>
                <div
                  className="accordion accordion-border-box"
                  id="genques-accordion"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="genques-headingOne">
                      <button
                        className={classnames("accordion-button", "fw-medium", {
                          collapsed: !col1,
                        })}
                        type="button"
                        onClick={t_col1}
                        style={{ cursor: "pointer" }}
                      >
                        {t("faq_title_1")}
                      </button>
                    </h2>
                    <Collapse isOpen={col1} className="accordion-collapse">
                      <div className="accordion-body">{t("faq_content_1")}</div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="genques-headingTwo">
                      <button
                        className={classnames("accordion-button", "fw-medium", {
                          collapsed: !col2,
                        })}
                        type="button"
                        onClick={t_col2}
                        style={{ cursor: "pointer" }}
                      >
                        {t("faq_title_2")}
                      </button>
                    </h2>
                    <Collapse isOpen={col2} className="accordion-collapse">
                      <div className="accordion-body">{t("faq_content_2")}</div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="genques-headingThree">
                      <button
                        className={classnames("accordion-button", "fw-medium", {
                          collapsed: !col3,
                        })}
                        type="button"
                        onClick={t_col3}
                        style={{ cursor: "pointer" }}
                      >
                        {t("faq_title_3")}
                      </button>
                    </h2>
                    <Collapse isOpen={col3} className="accordion-collapse">
                      <div className="accordion-body">{t("faq_content_3")}</div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="genques-headingFour">
                      <button
                        className={classnames("accordion-button", "fw-medium", {
                          collapsed: !col4,
                        })}
                        type="button"
                        onClick={t_col4}
                        style={{ cursor: "pointer" }}
                      >
                        {t("faq_title_4")}
                      </button>
                    </h2>
                    <Collapse isOpen={col4} className="accordion-collapse">
                      <div className="accordion-body">{t("faq_content_4")}</div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="genques-headingFour">
                      <button
                        className={classnames("accordion-button", "fw-medium", {
                          collapsed: !col5,
                        })}
                        type="button"
                        onClick={t_col5}
                        style={{ cursor: "pointer" }}
                      >
                        {t("faq_title_5")}
                      </button>
                    </h2>
                    <Collapse isOpen={col5} className="accordion-collapse">
                      <div className="accordion-body">{t("faq_content_5")}</div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="genques-headingFour">
                      <button
                        className={classnames("accordion-button", "fw-medium", {
                          collapsed: !col6,
                        })}
                        type="button"
                        onClick={t_col6}
                        style={{ cursor: "pointer" }}
                      >
                        {t("faq_title_6")}
                      </button>
                    </h2>
                    <Collapse isOpen={col6} className="accordion-collapse">
                      <div className="accordion-body">{t("faq_content_6")}</div>
                    </Collapse>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    </>
  );
};

export default BurnPage;
