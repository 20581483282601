import React, { memo, useMemo, useState, useEffect, useContext } from "react";
import { WalletTokensBurn, WalletAmountTokensBurn } from "../../types/Wallet";
import { FooterContext } from "../../context/FooterContext";
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Input, Pagination, PaginationItem, PaginationLink, UncontrolledDropdown } from "reactstrap";
import { useTruncateFromMiddle } from "use-truncate-from-middle";
import logo from "assets/images/favicon.png";

const Button = ({
  wallet,
  className,
  isWallet = false,
  width = "128px", // width (total)= content width + border width + padding
  font = "18px/18px Arial, sans-serif",
}) => {
  const btnRef = React.useRef(null);

  const { truncatedText } = useTruncateFromMiddle({
    ref: btnRef,
    originalText: wallet || "",
    middleChars: "...",
  });

  return useMemo(
    () => (
      <button
        onClick={() => {
          isWallet &&
            window.open("https://solscan.io/token/" + wallet, "_blank");
        }}
        ref={btnRef}
        className={className}
        style={{
          width: width,
          display: "block",
          padding: 0,
          marginLeft: 10,
          borderColor: "#1a1d2100",
          background: "#1a1d2100",
        }}
      >
        {truncatedText}
      </button>
    ),
    [truncatedText]
  );
};
const MemoButton = memo(Button);

export const AccountTableComponent = ({
  accounts,
  handleWalletSelect,
  handleInputBurnTokenChange,    
  handlePercentageClick,
  currentPage,
  itemsPerPage,
  onPageChange,
  onItemsPerPageChange,
}: {
  accounts: WalletTokensBurn[];
  handleWalletSelect: (walletAddress: string) => void;  
  handleInputBurnTokenChange: (address: string, newValue: string) => void;
  handlePercentageClick: (address: string, percentage: number) => void;
  currentPage: number;
  itemsPerPage: number;
  onPageChange: (pageNumber: number) => void;
  onItemsPerPageChange: (items: number) => void;
}) => {
  
  // const { setFooterData, setAccountsTokenBurn, tokensBurn } = useContext(FooterContext);
  // const [accountsAmountTokensBurn, setAmountsTokenBurn] = useState<WalletAmountTokensBurn[]>([]);

  // Вычисление индексов для отображения текущей страницы
  const indexOfLastAccount = currentPage * itemsPerPage;
  const indexOfFirstAccount = indexOfLastAccount - itemsPerPage;
  const currentAccounts = accounts.slice(indexOfFirstAccount, indexOfLastAccount);

  const totalPages = Math.ceil(accounts.length / itemsPerPage);
  const pages = Array.from({ length: totalPages }, (_, index) => index);

  return (
    <>
      {currentAccounts.map((account: WalletTokensBurn, index: number) => (
        <tr key={account.address}>
          <th scope="row">
            <div className="form-check">
              <Input
                className="form-check-input"
                type="checkbox"
                onChange={() => handleWalletSelect(account.address)}
                checked={account.metaChecked}
              />
            </div>
          </th>
          <td>
            <div className="d-flex gap-2 align-items-center">
            <div className="flex-shrink-0">
                {account.info.image.length ? (
                  <img
                    src={account.info.image}
                    alt=""
                    className="avatar-xs rounded-circle"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.src = logo; // Подставляем резервное изображение из переменной logo
                      target.onerror = null; // Очищаем обработчик onError
                      e.preventDefault(); // Предотвращаем вывод ошибки в консоль
                    }}
                  />
                ) : (
                  <img
                    src={logo} // Подставляем резервное изображение из переменной logo
                    alt=""
                    className="avatar-xs rounded-circle"
                  />
                )}
              </div>
              <div className="flex-grow-1">
                <MemoButton
                  className="account-wallet"
                  isWallet
                  wallet={account.address}
                />
              </div>
            </div>
          </td>
          <td>
            <MemoButton
              className="account_info_name"
              wallet={account.info.name}
            />
          </td>
          <td>
            $ {Number(account.cost).toFixed(2)}
          </td>
          <td className={account.tvl < account.cost ? 'text-danger-emphasis' : account.tvl > account.cost ? 'text-success-emphasis' : ''}>
            $ {Number(account.tvl).toFixed(2)}
          </td>
          <td>
          <div className="input-group">
          <Input
              type="text"
              className="form-control" 
              placeholder="Token amount"
              aria-label="Token amount"
              aria-describedby="basic-addon2"
              value={account.balanceBurn.toFixed(account.info.decimals)}
              start-value={account.balance}
              onChange={(e) => handleInputBurnTokenChange(account.address, e.target.value)} // Добавляем обработчик изменений
            />
            <span className="input-group-text" id="basic-addon2">
              {account.info.symbol}
            </span>
            <button
              className="btn btn-outline-primary"
              type="button"
              id="button-addon2"
              onClick={() => handlePercentageClick(account.address, 0.25)}
            >
              25%
            </button>
            <button
              className="btn btn-outline-secondary"
              type="button"
              id="button-addon2"
              onClick={() => handlePercentageClick(account.address, 0.5)}
            >
              50%
            </button>
            <button
              className="btn btn-outline-success"
              type="button"
              id="button-addon2"
              onClick={() => handlePercentageClick(account.address, 1)}
            >
              100%
            </button>
          </div>
          </td>
        </tr>
      ))}
      <tr>
        <td colSpan={6}>
          <div className="justify-content-end d-flex align-items-center">
              Total tokens ({accounts.length}) 
              Show 
              <ButtonGroup className="ms-2">
                <UncontrolledDropdown>
                  <DropdownToggle tag="button" className="btn btn-light">
                    {itemsPerPage} <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => onItemsPerPageChange(50)}>50</DropdownItem>
                    <DropdownItem onClick={() => onItemsPerPageChange(100)}>100</DropdownItem>
                    <DropdownItem onClick={() => onItemsPerPageChange(250)}>250</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ButtonGroup>
              <span className="ms-2">per page</span>
              <Pagination listClassName="justify-content-end ms-3 mb-0">
                {/* Кнопка First */}
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink onClick={() => onPageChange(1)}>First</PaginationLink>
                </PaginationItem>

                {/* Кнопка Prev */}
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink onClick={() => onPageChange(currentPage - 1)}> ← &nbsp; Prev </PaginationLink>
                </PaginationItem>

                {/* Первая страница */}
                <PaginationItem active={1 === currentPage}>
                  <PaginationLink onClick={() => onPageChange(1)}>1</PaginationLink>
                </PaginationItem>

                {/* Вторая страница */}
                {totalPages > 1 && (
                  <PaginationItem active={2 === currentPage}>
                    <PaginationLink onClick={() => onPageChange(2)}>2</PaginationLink>
                  </PaginationItem>
                )}

                {/* Если страниц больше 10 и текущая страница больше 4, показываем '...' */}
                {currentPage >= 4 && totalPages >= 8 && (
                  <PaginationItem disabled>
                    <PaginationLink>...</PaginationLink>
                  </PaginationItem>
                )}

                {/* Показ ближайших страниц к текущей */}
                {pages
                  .filter(page => page >= currentPage - 1 && page <= currentPage + 1 && page > 2 && page < totalPages - 1)
                  .map(page => (
                    <PaginationItem key={page} active={page === currentPage}>
                      <PaginationLink onClick={() => onPageChange(page)}>{page}</PaginationLink>
                    </PaginationItem>
                  ))}

                {/* Если текущая страница меньше totalPages - 3, показываем '...' */}
                {currentPage <= totalPages - 3 && totalPages >= 8 && (
                  <PaginationItem disabled>
                    <PaginationLink>...</PaginationLink>
                  </PaginationItem>
                )}

                {/* Предпоследняя страница */}
                {totalPages > 3 && (
                  <PaginationItem active={totalPages - 1 === currentPage}>
                    <PaginationLink onClick={() => onPageChange(totalPages - 1)}>{totalPages - 1}</PaginationLink>
                  </PaginationItem>
                )}

                {/* Последняя страница */}
                {totalPages > 2 && (
                <PaginationItem active={totalPages === currentPage}>
                  <PaginationLink onClick={() => onPageChange(totalPages)}>{totalPages}</PaginationLink>
                </PaginationItem>
                )}

                {/* Кнопка Next */}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink onClick={() => onPageChange(currentPage + 1)}> Next &nbsp; → </PaginationLink>
                </PaginationItem>

                {/* Кнопка Last */}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink onClick={() => onPageChange(totalPages)}>Last</PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>
        </td>
      </tr>
    </>
  );
};
