import React from "react";
import { Link } from "react-router-dom";
import { ConnectWalletButton } from "./ConnectWalletButton";
import { useTranslation } from "react-i18next";
import ChangeLang from "../../language/ChangeLang";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setLayoutTheme } from "../../../redux/actions";
import { LAYOUT_MODE_TYPES } from "../../constants/layout";
import Cookies from "universal-cookie";
import LightDark from "../../LightDark";

const Header = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useAppSelector((state) => state.layoutModeType);
  return (
    <React.Fragment>
      <div className="header d-flex align-items-center justify-content-between">
        <div
          style={{
            display: "flex",
          }}
        >
          <div className="navbar-nav">
            <div className="nav-item"></div>
            <div
              style={{
                display: "flex",
              }}
              className="nav-item"
            >
              <Link
                to={""}
                className={"nav-link menu-link burn-button-icon-menu   "}
              >
                <i
                  style={{
                    fontSize: "1.4rem",
                    position: "relative",
                    left: 6,
                    top: 2,
                    marginRight: "0.5rem",
                  }}
                  className="las la-fire-alt hide_mobile"
                ></i>
                {t("burn_acc")}
              </Link>
            </div>
            <div
              style={{
                display: "flex",
              }}
              className="nav-item"
            >
              <Link to={"burntokens"} className={"nav-link menu-link  basic-icon"}>
                <i
                  style={{
                    fontSize: "1.4rem",
                    position: "relative",
                    left: 6,
                    top: 2,
                    marginRight: "0.5rem",
                    color: "var(--vz-header-item-sub-color)",
                  }}
                  className="las la-coins hide_mobile"
                ></i>
                {t("burn_token_acc")}
              </Link>
            </div>
            <div
              style={{
                display: "flex",
              }}
              className="nav-item"
            >
              <Link to={"about"} className={"nav-link menu-link  basic-icon"}>
                <i
                  style={{
                    fontSize: "1.4rem",
                    position: "relative",
                    left: 6,
                    top: 2,
                    marginRight: "0.5rem",
                    color: "var(--vz-header-item-sub-color)",
                  }}
                  className="las la-id-card-alt hide_mobile"
                ></i>
                {t("about")}
              </Link>
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
            className="right social"
          >
            <ConnectWalletButton />
            <div
              className="jj"
              style={{
                marginLeft: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle hide_mobile"
                rel="noreferrer"
                href="https://t.me/dexscreeneronline"
                target={"_blank"}
              >
                <i
                  style={{
                    fontSize: "1.4rem",
                    color: "var(--vz-header-item-sub-color)",
                  }}
                  className="lab la-telegram"
                ></i>
              </a>
              <a
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle hide_mobile"
                rel="noreferrer"
                href="https://x.com/anubis_trade"
                target={"_blank"}
              >
                <i
                  style={{
                    fontSize: "1.4rem",
                    color: "var(--vz-header-item-sub-color)",
                  }}
                  className="lab la-twitter"
                />
              </a>
              <a
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle hide_mobile"
                rel="noreferrer"
                href="https://www.youtube.com/@AnubisTradeGroup"
                target={"_blank"}
              >
                <i
                  style={{
                    fontSize: "1.4rem",
                    color: "var(--vz-header-item-sub-color)",
                  }}
                  className="lab la-youtube"
                />
              </a>
              <a
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle hide_mobile"
                rel="noreferrer"
                href="https://medium.com/@anubis-trade"
                target={"_blank"}
              >
                <i
                  style={{
                    fontSize: "1.4rem",
                    color: "var(--vz-header-item-sub-color)",
                  }}
                  className="lab la-medium"
                ></i>
              </a>
              <a
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle hide_mobile"
                href="https://t.me/burnsplbot?start=r-czyj7ih970q"
                target={"_blank"}
                rel="noreferrer"
              >
                <i
                  style={{
                    fontSize: "1.4rem",
                    color: "var(--vz-header-item-sub-color)",
                  }}
                  className="las la-robot"
                ></i>
              </a>
              <LightDark
                layoutMode={theme}
                onChangeLayoutMode={() => {
                  dispatch(
                    setLayoutTheme(
                      theme === LAYOUT_MODE_TYPES.DARKMODE
                        ? LAYOUT_MODE_TYPES.LIGHTMODE
                        : LAYOUT_MODE_TYPES.DARKMODE
                    )
                  );
                  document.documentElement.setAttribute(
                    "data-bs-theme",
                    theme === LAYOUT_MODE_TYPES.DARKMODE
                      ? LAYOUT_MODE_TYPES.LIGHTMODE
                      : LAYOUT_MODE_TYPES.DARKMODE
                  );
                  const cookies = new Cookies();
                  cookies.set(
                    "theme",
                    theme === LAYOUT_MODE_TYPES.DARKMODE
                      ? LAYOUT_MODE_TYPES.LIGHTMODE
                      : LAYOUT_MODE_TYPES.DARKMODE,
                    {
                      path: "/",
                      expires: new Date(
                        new Date().getTime() + 30 * 24 * 60 * 60 * 1000
                      ),
                    }
                  );
                }}
              />
              <ChangeLang />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Header;
