import ru from "assets/images/flags/ru.svg";
import cn from "assets/images/flags/cn.svg";
import en from "assets/images/flags/us.svg";

export const languages = {
  en: {
    flag: en,
    label: "English",
  },
  ru: {
    flag: ru,
    label: "Русский",
  },
  cn: {
    flag: cn,
    label: "中国人",
  },
};
