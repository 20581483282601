import {
  Connection,
  PublicKey,
  Transaction,
  SystemProgram,
} from "@solana/web3.js";
import { Wallet } from "../types/Wallet";
import {
  getAccount, 
  createHarvestWithheldTokensToMintInstruction,
  createCloseAccountInstruction,
  TOKEN_2022_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { Buffer } from "buffer";
import Cookies from "universal-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../constants/api";

const QUICKNODE_URL = process.env.REACT_APP_QUICKNODE_URL;

window.Buffer = Buffer;

const connection = new Connection(QUICKNODE_URL, "confirmed");
const feeWalletPublicKey = new PublicKey(process.env.REACT_APP_FEE_WALLET_PUBLIC_KEY);
const TIMEOUT_MS = 60000; // Увеличение таймаута до 60 секунд

export const getTransactionDetail = async (batchLength, walletBurnAddress) => {
  const cookies = new Cookies();
  const referral = cookies.get("referral");

  try {
    const response = await axios.post(`${API_URL}/get-wallet`, { referral, batchLength, walletBurnAddress });
    const { walletReferralAddress, referralCode, referralPercentage, referralReward, netFeeAmount } = response.data;
    return { walletReferralAddress, referralCode, referralPercentage, referralReward, netFeeAmount };
  } catch (error) {
    console.error("Error fetching wallet address from API:", error);
    throw new Error("Failed to fetch wallet address from API");
  }
};

export const burnAccounts = async (
  publicKey: PublicKey | null,
  accounts: Wallet[],
  updateAccounts: (callback: (prevAccounts: Wallet[]) => Wallet[]) => void,
  t: (key: string) => string
): Promise<Wallet[]> => {
  if (!publicKey) {
    return accounts;
  }

  const burnBatch = async (batch: Wallet[]): Promise<boolean> => {
    let proceeded = false;
    try {
      const transaction = new Transaction();
      const { blockhash } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = publicKey;
      const burnedAccounts = batch.length;

      const { walletReferralAddress, referralCode, referralPercentage, referralReward, netFeeAmount } = await getTransactionDetail(burnedAccounts, publicKey.toBase58());

      if (referralReward > 0 && walletReferralAddress) {
        const referralInstruction = SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey(walletReferralAddress),
          lamports: BigInt(referralReward),
        });
        transaction.add(referralInstruction);
      }

      const transferInstruction = SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: feeWalletPublicKey,
        lamports: BigInt(netFeeAmount),
      });

      transaction.add(transferInstruction);

      for (const account of batch) {
        const accountPubKey = new PublicKey(account.associated_account);
        const programId = account.isToken2022 ? TOKEN_2022_PROGRAM_ID : TOKEN_PROGRAM_ID;

        // if (account.isToken2022) {
        //   const tokenPubKey = new PublicKey(account.address);
        //   const tokenAccountInfo = await getAccount(connection, accountPubKey, undefined, TOKEN_2022_PROGRAM_ID);
        //   const withheldFeeBalance = tokenAccountInfo.withheldAmount;

        // };

        const instruction = createCloseAccountInstruction(
          accountPubKey,
          publicKey,
          publicKey,
          [],
          programId
        );
        transaction.add(instruction);
      }

      let signature;

      if (window.solana) {
        if (!window.solana.isConnected) {
          await window.solana.connect();
        }
        const signedTransaction = await window.solana.signAndSendTransaction(
          transaction
        );
        signature = signedTransaction.signature;
      } else if (window.solflare) {
        if (!window.solflare.isConnected) {
          await window.solflare.connect();
        }
        const signedTransaction = await window.solflare.signAndSendTransaction(
          transaction
        );
        signature = signedTransaction.signature;
      } else {
        throw new Error("No Solana wallet found");
      }

      const toastId = toast(t("wait_tx"), {
        autoClose: false,
      });

      let confirmed = false;
      const startTime = Date.now();
      while (!confirmed && (Date.now() - startTime) < TIMEOUT_MS) {
        try {
          const result = await connection.confirmTransaction(
            signature,
            "processed"
          );

          if (result.value.err) {
            console.error("Transaction failed:", result.value.err);
          } else {
            confirmed = true;
          }
        } catch (error) {
          console.error("Error confirming transaction:", error);
        }
      }

      toast.dismiss(toastId);

      if (!confirmed) {
        throw new Error("Transaction timeout");
      }

      await axios.post(`${API_URL}/record-transaction`, {
        tx: signature,
        address: publicKey.toBase58(),
        referral_code: referralCode,
        transaction_time: new Date().toISOString(),
        burned_accounts: burnedAccounts,
        referral_percentage: referralPercentage,
        referral_reward: referralReward,
        success: confirmed ? 1 : 0,
        type: 1,
      });

      if (confirmed) {
        updateAccounts((prevAccounts) =>
          prevAccounts.filter(
            (acc) =>
              !batch.some(
                (burnedAcc) =>
                  burnedAcc.associated_account === acc.associated_account
              )
          )
        );
        toast(t("burned"), {
          type: "success",
        });
        proceeded = true;
      } else {
        toast(t("notburned"), {
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error while burning tokens:", error);
      toast(t("notburned"), {
        type: "error",
      });
    }
    return proceeded;
  };

  const batchSize = 20;
  for (let i = 0; i < accounts.length; i += batchSize) {
    const batch = accounts.slice(i, i + batchSize);
    const result = await burnBatch(batch);
    if (!result) {
      toast(t("errorburned"), {
        type: "error",
      });
      break;
    }
  }

  const updatedAccounts = accounts.filter((acc) => !acc.metaChecked);
  return updatedAccounts;
};
