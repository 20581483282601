import { Card, CardBody, Progress } from "reactstrap";
import React from "react";
import { useTranslation } from "react-i18next";

export const ProgressComponent = ({
  progress,
  totalTasks,
  loadedTasks,
}: {
  progress: number;
  totalTasks: number;
  loadedTasks: number;
}) => {
  const { t } = useTranslation();  
  // console.log(progress, totalTasks, loadedTasks);
  return (
    <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
      <td colSpan={6}>
        <Card className="bg-light overflow-hidden shadow-none ">
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1">
                <h6 className="mb-0 progress-text">
                  <b className="text-secondary">{progress.toFixed(2)}%</b>{" "}
                  {t("progress")}
                </h6>
              </div>
              <div className="flex-shrink-0 progress-text">
                <h6 className="mb-0">
                  {totalTasks - loadedTasks} {t("remains")}
                </h6>
              </div>
            </div>
          </CardBody>
          <div>
            <Progress
              value={progress}
              color="info"
              className="bg-info-subtle rounded-0"
            />
          </div>
        </Card>
      </td>
    </tr>
  );
};
