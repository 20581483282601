import { Wallet } from "../../types/Wallet";
import { Input } from "reactstrap";
import React, { memo, useMemo } from "react";
import { useTruncateFromMiddle } from "use-truncate-from-middle";

const Button = ({
  wallet,
  className,
  isWallet = false,
  width = "128px", // width (total)= content width + border width + padding
  font = "18px/18px Arial, sans-serif",
}) => {
  const btnRef = React.useRef(null);

  const { truncatedText } = useTruncateFromMiddle({
    ref: btnRef,
    originalText: wallet || "",
    middleChars: "...",
  });

  return useMemo(
    () => (
      <>
        <button
          onClick={() => {
            isWallet &&
              window.open(
                "https://solscan.io/token/" + wallet,
                "_blank"
              );
          }}
          ref={btnRef}
          className={className}
          style={{
            width: width,
            display: "block",
            padding: 0,
            marginLeft: 10,
            borderColor: "#1a1d2100",
            background: "#1a1d2100",
          }}
        >
          {truncatedText}
        </button>
      </>
    ),
    [truncatedText]
  );
};
const MemoButton = memo(Button);

export const AccountTableComponent = ({
  accounts,
  handleWalletSelect,
}: {
  accounts: Wallet[];
  handleWalletSelect: (index: number) => void;
}) => {
  return (
    <>
      {accounts.map((account: Wallet, index: number) => (
        <tr key={account.address}>
          <th scope="row">
            <div className="form-check">
              <Input
                className="form-check-input"
                type="checkbox"
                onChange={() => handleWalletSelect(index)}
                checked={account.metaChecked}
              />
            </div>
          </th>
          <td>
            <div className="d-flex gap-2 align-items-center">
              <div className="flex-shrink-0">
                {account.info.image.length ? (
                  <img
                    src={account.info.image}
                    alt=""
                    className="avatar-xs rounded-circle"
                  />
                ) : (
                  <div className="avatar-xs rounded-circle"></div>
                )}
              </div>
              <div className="flex-grow-1">
                <MemoButton
                  className="account-wallet"
                  isWallet
                  wallet={account.address}
                />
              </div>
            </div>
          </td>
          <td>
            <MemoButton
              className="account_info_name"
              wallet={account.info.name}
            />
          </td>
          <td>
            {account.balance} / {account.info.symbol}
          </td>
        </tr>
      ))}
    </>
  );
};
