import React, { memo, useMemo } from "react";
import { useTruncateFromMiddle } from "use-truncate-from-middle";
import { useWallet } from "@solana/wallet-adapter-react";
import { motion } from "framer-motion";

const Button = ({ wallet, width = "128px", font = "18px/18px Arial, sans-serif" }) => {
  const btnRef = React.useRef(null);
  const { truncatedText } = useTruncateFromMiddle({
    ref: btnRef,
    originalText: wallet || "",
    middleChars: "...",
  });

  return (
    <button
      ref={btnRef}
      style={{
        width: width,
        display: "block",
        padding: 0,
        marginLeft: 10,
        borderColor: "#1a1d2100",
        background: "#1a1d2100",
      }}
    >
      {truncatedText}
    </button>
  );
};

const ButtonSmall = ({ wallet, width = "128px" }) => {
  const btnRef = React.useRef(null);
  const { truncatedText } = useTruncateFromMiddle({
    ref: btnRef,
    originalText: wallet || "",
    middleChars: "...",
  });

  return (
    <button
      ref={btnRef}
      style={{
        width: width,
        fontSize: 8,
        display: "block",
        textDecoration: "underline",
        color: "rgba(--var()",
        padding: 0,
        borderColor: "#1a1d2100",
        background: "#1a1d2100",
      }}
    >
      {truncatedText}
    </button>
  );
};

const MemoButton = memo(Button);
const MemoSmallButton = memo(ButtonSmall);

const BurnedWalletRow = ({ burnedWallet, burnedLoaded }) => {
  const { publicKey: wallet } = useWallet();
  const currentWallet = (wallet || "").toString();

  // console.log("Rendering BurnedWalletRow with:", burnedWallet);

  return useMemo(() => {
    return (
      <motion.tr
        initial={
          burnedLoaded
            ? {
                x: -200,
                opacity: 0,
                backgroundColor: "#3d78e3",
                color: "#fff",
              }
            : false
        }
        animate={{
          x: 0,
          opacity: 1,
          backgroundColor: burnedWallet.address === currentWallet ? "var(--vz-table-active-bg)" : "var(--vz-table-bg)",
        }}
        exit={{ opacity: 0 }}
        style={{
          color: "rgb(135, 138, 153)",
        }}
      >
        <td>
          {burnedWallet.type == 1 ? (
            <i
              style={{
                fontSize: "1.35rem",
                position: "relative",
                color: "#ff9200",
              }}
              className="las la-fire-alt"
            ></i>
          ) : burnedWallet.type == 2 ? (
            <i
              style={{
                color: "var(--vz-danger)",
                fontSize: "1.35rem",
                position: "relative",
              }}
              className="las la-coins"
            ></i>
          ) : null}
        </td>
        <th scope="row">
          <a
            rel="noopener"
            target={"_blank"}
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
            href={`https://solscan.io/tx/${burnedWallet.tx}`}
          >
            <MemoButton wallet={burnedWallet.address} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h6
                style={{
                  margin: 0,
                  fontSize: 12,
                }}
              >
                tx:
              </h6>
              <MemoSmallButton wallet={burnedWallet.tx} />
            </div>
          </a>
        </th>
        <td>{burnedWallet.burned_accounts}</td>
        <td>{Number(burnedWallet?.total_rewards).toFixed(3)} SOL</td>
        <td>
          {burnedWallet.success ? (
            <i
              style={{
                fontSize: "1.35rem",
                position: "relative",
                color: "rgb(var(--vz-success-rgb))",
              }}
              className="las la-check-circle"
            ></i>
          ) : (
            <i
              style={{
                color: "var(--vz-danger)",
                fontSize: "1.35rem",
                position: "relative",
              }}
              className="las la-times-circle"
            ></i>
          )}
        </td>
      </motion.tr>
    );
  }, [currentWallet, burnedWallet]);
};

export default memo(BurnedWalletRow);
