import { Wallet } from "../../types/Wallet";
import { RefreshButtonComponent } from "./RefreshButtonComponent";
import React from "react";
import { AccountTableComponent } from "./AccountTableComponent";
import { ProgressComponent } from "./ProgressComponent";
import { useTranslation } from "react-i18next";

export const WalletDetails = ({
  accounts,
  handleWalletSelect,
  loadWallets,
  progress,
  totalTasks,
  loadedTasks,
  tasksLoaded,
}: {
  accounts: Wallet[];
  handleWalletSelect: (index: number) => void;
  loadWallets: () => void;
  progress: number;
  totalTasks: number;
  loadedTasks: number;
  tasksLoaded: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <tbody>
      <>
        {!tasksLoaded ? (
          <ProgressComponent
            progress={progress}
            totalTasks={totalTasks}
            loadedTasks={loadedTasks}
          />
        ) : (
          <AccountTableComponent
            accounts={accounts}
            handleWalletSelect={handleWalletSelect}
          />
        )}
        {tasksLoaded && accounts.length === 0 && (
          <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
            <td colSpan={4}>
              <div className="alert alert-success" role="alert">
                <strong>{t("clear_wallet")}</strong>
              </div>
            </td>
          </tr>
        )}
        <RefreshButtonComponent loadWallets={loadWallets} />
      </>
    </tbody>
  );
};
