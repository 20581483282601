import React from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { trackEvent } from 'services/analytics';

export const ConnectWalletButton = () => {
  const handleWalletConnect = () => {
    trackEvent('Wallet', 'Connect', 'User connected their wallet');
  };

  return (
    <React.Fragment>
      <WalletMultiButton onClick={handleWalletConnect} />
    </React.Fragment>
  );
};