import { createSlice } from "@reduxjs/toolkit";
import { setLayoutTheme } from "./actions";
import { LAYOUT_MODE_TYPES } from "components/constants/layout";

export interface LayoutState {
  layoutModeType: LAYOUT_MODE_TYPES.LIGHTMODE | LAYOUT_MODE_TYPES.DARKMODE;
}

export const initialState: LayoutState = {
  layoutModeType: LAYOUT_MODE_TYPES.DARKMODE,
};

const LayoutSlice = createSlice({
  name: "LayoutSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setLayoutTheme, (state, action) => {
      state.layoutModeType = action.payload;
    });
  },
});

export default LayoutSlice.reducer;
