import { useEffect, useReducer, useRef, useState } from "react";
import { wsServer } from "../constants/ws";
import axios from "axios";
import { API_URL } from "../constants/api";
import { toast } from "react-toastify";
import { BurnedList } from "../types/Wallet";
import { useWallet } from "@solana/wallet-adapter-react";

function reducer(state, action) {
  switch (action.type) {
    case "add_new":
      // console.log("Adding new wallets to state:", action.wallets);
      return [...action.wallets, ...state]; // Новые записи добавляются в начало
    case "set_all":
      // console.log("Setting all wallets to state:", action.wallets);
      return action.wallets;
    default:
      throw new Error("Unknown action.");
  }
}

export const useWs = () => {
  const [burnedLoaded, setBurnedLoaded] = useState(false);
  const [burnedWallets, dispatch] = useReducer(reducer, []);
  const [onlyUserBurns, setOnlyUserBurns] = useState(false);
  const { publicKey: wallet } = useWallet();

  useEffect(() => {
    if (burnedWallets.length > 0 && !burnedLoaded) {
      setBurnedLoaded(true);
    }
  }, [burnedWallets, burnedLoaded]);

  useEffect(() => {
    const initialLoad = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/burned${onlyUserBurns ? `?wallet=${wallet}` : ""}`
        );
        dispatch({ type: "set_all", wallets: response.data });
      } catch (error) {
        toast("Error during loading last burned accounts", {
          type: "error",
        });
        // console.error("Error during loading last burned accounts:", error);
      }
    };
    initialLoad();
  }, [onlyUserBurns, wallet]);

  const clientRef = useRef(null);
  const [waitingToReconnect, setWaitingToReconnect] = useState(false);

  const addMessage = (message) => {
    const eventData = JSON.parse(message);
    // console.log("New message from WebSocket:", eventData);
    dispatch({ type: "add_new", wallets: eventData });
  };

  useEffect(() => {
    if (waitingToReconnect) {
      return;
    }

    if (!clientRef.current) {
      const client = new WebSocket(wsServer);
      clientRef.current = client;

      client.onerror = (e) => console.error(e);

      client.onopen = () => {
        // console.log("ws opened");
      };

      client.onclose = () => {
        if (clientRef.current) {
          // console.log("ws closed by server");
        } else {
          // console.log("ws closed by app component unmount");
          return;
        }

        if (waitingToReconnect) {
          return;
        }

        // console.log("ws closed");
        setWaitingToReconnect(true);
        setTimeout(() => setWaitingToReconnect(false), 5000);
      };

      client.onmessage = (message) => {
        // console.log("received message", message);
        addMessage(message.data);
      };

      return () => {
        // console.log("Cleanup");
        clientRef.current = null;
        client.close();
      };
    }
  }, [waitingToReconnect]);

  return {
    burnedWallets,
    onlyUserBurns,
    setOnlyUserBurns,
    dispatch,
    burnedLoaded,
  };
};
