import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../constants/api";
import { toast } from "react-toastify";
import { useWallet } from "@solana/wallet-adapter-react";
import Cookies from 'universal-cookie';

type ReferralStats = {
  referralPercentage: number;
  totalReferrals: number;
  totalReferralWalletsCount: number;
  totalBurnedAccounts: number;
  formattedEarnings: string;
  refLinks: string[];
};

export const useReferralStats = () => {
  const { publicKey: wallet } = useWallet();
  const [data, setData] = useState<ReferralStats | false>(false);
  useEffect(() => {
    const initialLoad = async () => {
      try {
        const cookies = new Cookies();
        const referralCode = cookies.get('referral');

        await axios
          .get(`${API_URL}/api/referral-info/${wallet}`, {
            headers: {
              'Referral-Code': referralCode || '', // Передаем реферальный код в заголовке
            },
          })
          .then((response) => {
            setData(response.data);
          });
      } catch (error) {
        toast("Error during loading referral information", {
          type: "error",
        });
      }
    };
    if (wallet) {
      initialLoad().then();
    } else {
      setData(false);
    }
  }, [wallet]);

  return data;
};
