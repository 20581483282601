import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import Masonry from "react-masonry-css";
import "./masonry.css";

import img3 from "../../assets/images/small/burn.png";
import img7 from "../../assets/images/small/screener.png";
import img8 from "../../assets/images/small/trading.png";
import logoart from "../../assets/images/small/logo_art.png";
import logoceo from "../../assets/images/small/logo_ceo.png";
import logomark from "../../assets/images/small/logo_marketing.png";
import logosmm from "../../assets/images/small/logo_smm.jpg";
import logo_code from "../../assets/images/small/logo_code.jpg";
import style from "./styles.module.scss";

import { Link } from "react-router-dom";

const About = () => {
  const { t } = useTranslation();

  document.title = "Anubis Trade - About Page";

  // {t('burn_acc')}

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
  };

  const teamList = [
    {
      id: 1,
      userImage: logoceo,
      name: "Anubis",
      designation: "Team Leader & PM & Full Stack Developer",
    },
    {
      id: 2,
      userImage: logomark,
      name: "God of Marketing",
      designation: "Marketing & SMM & Adv",
    },
    {
      id: 3,
      userImage: logoart,
      name: "God of ART",
      designation: "UI/UX Designer & Art",
    },
    {
      id: 4,
      userImage: logosmm,
      name: "God of SMM",
      designation: "SMM & Marketing",
    },
    {
      id: 5,
      userImage: logo_code,
      name: "God of Code",
      designation: "Full Stack Developer",
    },
  ];

  return (
    <>
    <Helmet>
        <title>Claim and Close Your Solana Token Accounts - Anubis Trade</title>
        <meta name="description" content="Recover and close your unused Solana token accounts effortlessly. Share with others and maximize your SOL savings! Powered by Anubis Trade." />
        <meta name="keywords" content="solana, token accounts, claim SOL, recover accounts, blockchain, close accounts, SOL, Anubis Trade" />      
        <meta name="author" content="@anubistrade" />
    </Helmet> 
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm={12}>
              <div className="justify-content-between d-flex align-items-center mt-5 mb-4">
                <h5
                  className={
                    "pb-1 text-decoration-underline text-decoration-underline justify-center " +
                    style.title
                  }
                >
                  {t("projects")}
                </h5>
              </div>

              <Row className="flex justify-content-center">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  <Col>
                    <Card>
                      <Link to={""}>
                        <img
                          src={img3}
                          className="card-img-top text-decoration-underline "
                          alt={t("burn_acc")}
                        />
                        <CardBody>
                          <h4 className="text-decoration-underline text-center mb-3">
                            {t("burn_acc")}
                          </h4>
                          <p>{t("project_burn_p1")}</p>
                          <p>{t("project_burn_p2")}</p>
                          <ul>
                            <li>{t("project_burn_li1")}</li>
                            <li>{t("project_burn_li2")}</li>
                            <li>{t("project_burn_li3")}</li>
                          </ul>
                          <p>{t("project_burn_p3")}</p>
                        </CardBody>
                      </Link>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <CardBody>
                        <h4 className="text-decoration-underline text-center mb-3">
                          {t("dex_screener")}
                        </h4>
                        <p>{t("project_screener_p1")}</p>
                        <p>{t("project_screener_p2")}</p>
                        <ul>
                          <li>{t("project_screener_li1")}</li>
                          <li>{t("project_screener_li2")}</li>
                          <li>{t("project_screener_li3")}</li>
                        </ul>
                      </CardBody>
                      <img
                        className="card-img rounded-0 img-fluid"
                        src={img8}
                        alt={t("dex_screener")}
                      />
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <img src={img7} className="card-img" alt={t("journal")} />
                      <CardBody>
                        <h4 className="text-decoration-underline text-center mb-3">
                          {t("journal")}
                        </h4>
                        <p>{t("project_journal_p1")}</p>
                        <ul>
                          <li>{t("project_journal_li1")}</li>
                          <li>{t("project_journal_li2")}</li>
                          <li>{t("project_journal_li3")}</li>
                        </ul>
                        <p>{t("project_journal_p3")}</p>
                      </CardBody>
                    </Card>
                  </Col>
                </Masonry>
              </Row>
            </Col>
          </Row>

          <Row xs={12}>
            <Col className="supp" xs={{ size: 12, order: 2 }}>
              <div className="justify-content-between d-flex align-items-center mt-5 mb-4">
                <h5
                  className={
                    "mb-0 pb-1 text-decoration-underline " + style.title
                  }
                >
                  {t("team")}
                </h5>
              </div>

              <div id="teamlist">
                <Row className="team-list grid-view-filter">
                  {(teamList || []).map((item: any, key: any) => (
                    <Col key={key}>
                      <Card className="team-box">
                        <div className="team-cover">
                          <img
                            src={item.backgroundImg}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <CardBody className="p-4">
                          <Row className="align-items-center team-row">
                            <Col lg={4} className="col">
                              <div className="team-profile-img">
                                <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                  {item.userImage != null ? (
                                    <img
                                      src={item.userImage}
                                      alt=""
                                      className="img-fluid d-block rounded-circle"
                                    />
                                  ) : (
                                    <div className="avatar-title text-uppercase border rounded-circle bg-light text-primary">
                                      {item.name.charAt(0) +
                                        item.name
                                          .split(" ")
                                          .slice(-1)
                                          .toString()
                                          .charAt(0)}
                                    </div>
                                  )}
                                </div>
                                <div className="team-content">
                                  <h5 className="fs-16 mb-1">{item.name}</h5>
                                  <p className="text-muted mb-0">
                                    {item.designation}
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>

          <Row className="about-project">
            <Col lg={12}>
              <div className="justify-content-between d-flex align-items-center mt-5 mb-4">
                <h4
                  className={
                    "mb-0 pb-1 text-decoration-underline " + style.title
                  }
                >
                  {t("about_project")}
                </h4>
              </div>
            </Col>

            <Row className="about-project">
              <h5 className="mb-0 pb-1 text-decoration-underline">
                {t("about_pr_text_h3_1")}
              </h5>

              <p>{t("about_pr_text_1")}</p>

              <p>{t("about_pr_text_2")}</p>

              <h5 className="mb-0 pb-1 text-decoration-underline">
                {t("about_pr_text_h3_2")}
              </h5>
              <h6>{t("about_pr_text_h4_1")}</h6>

              <p>{t("about_pr_text_3")}</p>

              <p>{t("about_pr_text_4")}</p>

              <h5 className="mb-0 pb-1 text-decoration-underline">
                {t("about_pr_text_h3_3")}
              </h5>
              <h6>{t("about_pr_text_h4_2")}</h6>

              <p>{t("about_pr_text_5")}</p>

              <p>{t("about_pr_text_6")}</p>
            </Row>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    </>
  );
};

export default About;
