import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import Cookies from "universal-cookie";

export const useRefHook = () => {
  const [params] = useSearchParams();
  useEffect(() => {
    const refParam = params.get("ref");
    const cookies = new Cookies();
    if (refParam && !cookies.get("referral")) {
      cookies.set("referral", refParam, {
        path: "/",
        expires: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
      });
    }
  });
};
