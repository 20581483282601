import React from "react";
import { Container } from "reactstrap";

const Page404 = () => {
  document.title = "Anubis Trade - 404";

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <h1>404</h1>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Page404;
